<template>
  <div class="templates">
    <h1>Templates</h1>
    <div class="project-select">
      <div v-if="projects !== null">
        <div class="project-select-items" v-for="project, index in projects" :key="index">
          <span>{{project.name}}</span>
          <input type="checkbox" @click="handleSelectProject(project)" :checked="project.active"/>
        </div>
      </div>
    </div>
    <table id="template-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Bucket</th>
        </tr>
      </thead>
      <tbody v-show="!templatesLoading">        
        <tr v-for="template, index in templates" :key="index">
           <td v-if="template!==null"><router-link :to="{ name: 'Template', params: {source: template.source, template_name: template.name}}">{{ template.name }}</router-link></td>
           <td v-if="template!==null">{{ template.bucket}}</td>
        </tr>
      </tbody>
      <tbody>
        <font-awesome-icon v-show="templatesLoading" :icon="['fas', 'spinner']" class="loading-component"/>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'Templates',
  computed: {
    ...mapGetters({
      templates: "getTemplates",
      templatesLoading: "templatesLoading",
      projects: "getProjects",
    })
  },
  methods: {
    ...mapActions(['loadTemplates', 'loadProjectMetadata', 'loadProjects']),
    async handleSelectProject(project){
      project.active = !project.active
      await this.loadTemplates()
    },
  },
  async mounted () {
    if(this.projects===null){
      await this.loadProjects()
    }
    await this.loadTemplates()
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-component {
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

.project-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

.project-select{
  margin:auto;
  text-align: center;
}
</style>
